<template>
  <div class="d-block" v-if="modalShow">
    <b-modal
      v-model="modalShow"
      ref="modalRedeemPoint"
      hide-header
      hide-footer
      centered
      body-class="p-0"
    >
      <div class="m-3">
        <div class="text-center title mb-2 f-xl">ยืนยันการแลกของรางวัล</div>

        <div class="p-3 bg-gray rounded">
          <b-row class="justify-content-center row-gap-sm">
            <b-col cols="5"
              ><div class="f-bold text-nowrap">คะแนนสะสมปัจจุบัน</div>
            </b-col>
            <b-col cols="1"> : </b-col>
            <b-col cols="5" class="text-right f-bold">
              {{ totalPoint | numeral("0,0") }} คะแนน
            </b-col>
            <b-col cols="5"><div class="f-bold">คะแนนที่ต้องใช้</div> </b-col>
            <b-col cols="1"> : </b-col>
            <b-col cols="5" class="text-right text-danger f-bold">
              - {{ (detail.point_used * redeem_count) | numeral("0,0") }} คะแนน
            </b-col>
            <b-col cols="5"><div class="f-bold">คะแนนคงเหลือ</div> </b-col>
            <b-col cols="1"> : </b-col>
            <b-col cols="5" class="text-right f-bold">
              {{
                (totalPoint - detail.point_used * redeem_count) | numeral("0,0")
              }}
              คะแนน
            </b-col>
          </b-row>
        </div>

        <!-- <hr /> -->
        <div class="mt-2 text-danger f-bold">
          หมายเหตุ:
          {{
            $store.state.theme.redeem_remark ||
            "หากทำการยืนยันแล้วจะไม่สามารถยกเลิกหรือแก้ไขการแลกของรางวัลได้"
          }}
        </div>
      </div>
      <hr />
      <div class="p-3 text-center">
        <b-button
          @click.prevent="submitRedeem"
          class="w-100"
          variant="primary"
          :disabled="isDisable"
          :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
          >ยืนยัน</b-button
        >
        <b-button
          class="w-100 mt-2"
          @click.prevent="close"
          variant="outline-primary"
          >ยกเลิก</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      required: true,
      type: Object
    },
    totalPoint: {
      required: true,
      type: Number
    },
    address: {
      required: true,
      type: Object
    },
    redeemQty: {
      required: false
    }
  },
  data() {
    return {
      modalShow: false,
      total: 0,
      body: {
        redeem_id: 0,
        product_id: 0,
        branch_id: 0,
        home_address: "",
        province: "",
        subdistrict: "",
        district: "",
        zip_code: "",
        note: "",
        redeem_type_id: 1,
        redeem_count: 1
      },
      redeem_count: this.$route.query.qty || 1,
      isDisable: false
    };
  },
  watch: {
    redeemQty(val) {
      this.redeem_count = val;
    }
  },
  methods: {
    show() {
      this.modalShow = true;
      this.total = this.totalPoint - this.detail.point_used;
    },
    close() {
      this.$emit("hide");
      this.modalShow = false;
    },
    async submitRedeem() {
      let detail = this.detail;
      if (this.detail.pick_up_type_id == 1) {
        this.$emit("confirm");
      } else {
        this.$emit("showLoading");
        this.body.redeem_id = detail.redeem_id;
        this.body.product_id = detail.product_id;
        this.body.first_name = this.address.first_name_th;
        this.body.last_name = this.address.last_name_th;
        this.body.telephone = this.address.telephone;
        this.body.home_address = this.address.home_address;
        this.body.subdistrict = this.address.subdistrict;
        this.body.district = this.address.district;
        this.body.province = this.address.province;
        this.body.zip_code = this.address.zip_code;
        this.body.note = this.address.note;
        this.body.redeem_type_id = this.$route.query.type;
        this.body.redeem_count = this.redeem_count;
        this.isDisable = true;
        await this.$axios
          .post(
            `${process.env.VUE_APP_API}/api/v1/Redeem/redeem_pick_at_home`,
            this.body
          )
          .then(data => {
            this.$emit("hideLoading");
            if (data.result == 1) {
              this.isDisable = false;
              this.$emit("redeemSuccess", data);
              this.close();
            } else {
              this.isDisable = false;
              this.msg = data.detail;
              this.$emit("redeemError", data);
              this.close();
            }
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 600;
}
.w-80 {
  width: 80%;
}
</style>
