<template>
  <div>
    <section>
      <div v-if="detail.image_url">
        <img :src="detail.image_url" class="w-100" />
      </div>
      <div v-else>
        <img :src="img" class="w-100" />
      </div>
      <div class="px-3 pt-3">
        <div class="f-xl f-bold line-clamp-2">
          {{ detail.name }}
        </div>
        <b-row class="align-items-center">
          <b-col cols="7">
            <b-badge
              variant="expired"
              v-if="$store.state.theme.line_display_redeem_expire == '1'"
            >
              แลกได้ถึง :
              {{ $moment(detail.valid_to).add(543, "y").format("DD MMM YYYY") }}
            </b-badge>
          </b-col>
          <b-col cols="5" class="text-right">
            <div v-if="detail.out_of_stock" class="status-false">
              ของรางวัลหมด
            </div>
            <b-badge pill variant="list-redeem">
              <div class="point d-flex align-items-center">
                <span class="fa-stack fa-1x icon-background mr-2">
                  <b-img
                    :src="$store.state.domain + $store.state.theme.point_logo"
                    class="img-point"
                    v-if="$store.state.theme.point_logo"
                  ></b-img>
                  <!-- {{ $store.state.domain + $store.state.theme.point_logo }} -->
                  <font-awesome-icon
                    v-else
                    icon="star"
                    class="point-icon-color"
                  />
                  <!-- :color="item.theme_icon_color" -->
                  <!-- <font-awesome-icon icon="star" class="point-icon-color" /> -->
                </span>
                <!-- <span class="fa-stack fa-1x icon-background mr-2">
                  <font-awesome-icon icon="star" class="point-icon-color" />
                </span> -->
                {{ detail.point_used | numeral("0,0") }}
              </div>
            </b-badge>
          </b-col>
        </b-row>
      </div>
      <hr />
      <div class="px-3">
        <!-- <div class="p-3"> -->
        <template v-if="$store.state.theme.is_center_point == '1'">
          <div class="d-inline-flex w-100">
            <div>คะแนนสะสมปัจจุบัน</div>
            <div class="ml-auto">{{ totalPoint | numeral("0,0") }} คะแนน</div>
          </div>
          <div class="d-inline-flex w-100">
            <div>จำนวนสินค้าที่แลก</div>
            <div class="ml-auto d-inline-flex align-items-center">
              <b-button
                variant="custom-link"
                :disabled="detail.redeemable === 'N' || redeemQty == 1"
                @click="redeemQty -= 1"
              >
                <font-awesome-icon
                  icon="minus-circle"
                  :style="{ color: $store.state.theme.font_color }"
              /></b-button>
              <div class="qty">{{ redeemQty }}</div>

              <b-button
                variant="custom-link"
                :disabled="
                  detail.redeemable === 'N' ||
                  redeemQty >= detail.total_redeemable ||
                  detail.point_used * (redeemQty + 1) > totalPoint
                "
                @click="addQty()"
              >
                <font-awesome-icon
                  icon="plus-circle"
                  :style="{ color: $store.state.theme.font_color }"
                />
              </b-button>
              <br />
            </div>
          </div>

          <div class="d-inline-flex w-100">
            <div>จำนวนคะแนนที่ใช้</div>
            <div class="ml-auto">
              {{ (detail.point_used * redeemQty) | numeral("0,0") }} คะแนน
            </div>
          </div>
          <div class="d-inline-flex w-100">
            <div>คะแนนคงเหลือ</div>
            <div class="ml-auto">
              {{
                (totalPoint - detail.point_used * redeemQty) | numeral("0,0")
              }}
              คะแนน
            </div>
          </div>

          <!-- </div> -->
          <div class="text-right" v-if="detail.total_redeemable != 999">
            <small class="text-danger f-xs"
              >คงเหลือ {{ detail.total_redeemable }} ชิ้น</small
            >
          </div>
          <hr />
        </template>
        <div>
          <div class="f-lg f-bold">รายละเอียด</div>
          <div
            v-html="detail.description"
            @click="handleClick"
            v-if="detail.description"
          />
          <div class="text-center mb-2" v-else>ไม่มีรายละเอียด</div>
        </div>
      </div>
    </section>

    <Footer
      v-if="
        !(this.$route.query.page == 'history' && this.$route.query.type == 1)
      "
      @onClick="handleConfirmRedeem"
      :isDisable="
        point < detail.point_used ||
        detail.redeemable === 'N' ||
        detail.out_of_stock ||
        (this.$route.query.page == 'history' && detail.is_redeem == 1)
      "
      :text="textButton()"
    />

    <ModalRedeem
      ref="modalRedeem"
      :detail="detail"
      @confirmRedeem="handleConfirmRedeem"
    />
    <ModalRedeemAdress
      ref="modalRedeemAddress"
      :desciptionMsg="desciptionMsg"
      btnMsg="ยืนยัน"
      titleMsg="ยืนยันรับของขวัญ"
      :hideClose="hideModalClose"
      @submitModal="handleAddressFill"
    />
    <ModalRedeemPointNewLayout
      ref="modalRedeemPoint"
      :detail="detail"
      :totalPoint="totalPoint + branchPoint"
      :address="addressList"
      :redeemQty="redeemQty"
      @redeemSuccess="redeemSuccess"
      @confirm="handleModalPointClick"
      @showLoading="showLoading"
      @hideLoading="hideLoading"
      @redeemError="redeemError"
    />
    <ModalSelectBranch
      ref="ModalSelectBranch"
      :branch="branch"
      @success="success"
      @error="error"
      :detail="detail"
      :redeemQty="redeemQty"
      @showPointUsedModal="showPointUsedModal"
      @showLoading="showLoading"
      @hideLoading="hideLoading"
      @onBranchChange="onBranchChange"
    />
    <ModalAlert ref="modalAlert" :text="msg" @hide="refreshPage" />
    <ModalError ref="modalError" :text="msg" />
    <ModalLoading ref="modalLoading" />
  </div>
</template>

<script>
import ModalRedeem from "@/components/reward/modal/ModalRedeem";
import ModalRedeemAdress from "@/components/reward/modal/ModalRedeemAdress";
import ModalSelectBranch from "@/components/reward/modal/ModalSelectBranch";
import ModalRedeemPointNewLayout from "@/components/reward/modal/ModalRedeemPointNewLayout";
import ModalAlert from "@/components/alert-modal/ModalAlert";
import ModalLoading from "@/components/other/ModalLoading.vue";
import ModalError from "@/components/alert-modal/ModalError";
import picture from "@/assets/images/default-image.png";
import moment from "moment";
export default {
  components: {
    ModalRedeem,
    ModalAlert,
    ModalError,
    ModalSelectBranch,
    ModalRedeemAdress,
    ModalRedeemPointNewLayout,
    ModalLoading
  },
  props: {
    detail: {
      required: true,
      type: Object
    },
    branch: {
      required: true,
      type: Array
    },
    addressFlag: {
      required: true,
      type: Boolean
    },
    id: {
      required: true,
      type: Number
    },
    totalPoint: {
      required: true,
      type: Number
    },
    addressList: {
      required: true,
      type: Object
    },
    buttonName: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      img: picture,
      msg: "",
      typeRedeem: 2,
      desciptionMsg: "",
      btnMsg: "",
      titleMsg: "",
      disabled: false,
      redeemQty: 1,
      branchPoint: 0,
      hideModalClose: false
    };
  },
  computed: {
    point() {
      return this.$store.state.shortProfile.total_point;
    }
  },
  mounted() {
    if (this.$route.query.edit) {
      this.redeemQty = this.$route.query.qty;
      this.$router.replace(
        `/reward/${this.id}?type=${this.$route.query.type}&o_id=0`
      );
      setTimeout(() => {
        this.$refs.modalRedeemPoint.show(true);
      }, 500);
    }
  },
  methods: {
    handleConfirmRedeem() {
      if (this.$route.query.page == "history" && this.detail.is_redeem == 0) {
        return this.$router.push("/privilege?title=สิทธิพิเศษ");
      } else {
        return this.confirmRedeem();
      }
    },
    textButton() {
      if (this.$route.query.page == "history" && this.detail.is_redeem == 0) {
        return "ไปยังหน้าใช้งานคูปอง";
      } else {
        if (this.$route.query.page == "history" && this.detail.is_redeem == 1) {
          return "คูปองถูกใช้แล้ว";
        } else {
          if (this.point < this.detail.point_used) {
            return "แต้มแลกของขวัญไม่พอ";
          } else if (this.detail.redeemable == "N") {
            return "ไม่สามารถแลกของขวัญได้";
          } else {
            return this.buttonName || "แลกของขวัญ";
          }
        }
      }
    },
    dateFormat(date) {
      return moment(date).locale("th").format("lll").replace("เวลา", "");
    },

    handleClick(e) {
      if (e.target.id == "handle-click") {
        this.$liff
          .sendMessages(JSON.parse(e.target.getAttribute("data-message")))
          .then(() => {
            console.log("message sent");
            this.$liff.closeWindow();
          })
          .catch(err => {
            console.log("error", err);
          });
      }
    },
    confirmRedeem() {
      if (
        (this.branch.length == 1 && this.detail.pick_up_type_id == 1) ||
        this.detail.pick_up_type_id == 3
      )
        this.$refs.ModalSelectBranch.confirmRedeem();
      else if (this.detail.pick_up_type_id == 2) {
        if (this.addressFlag) {
          this.$refs.modalRedeemPoint.show(true);
        } else {
          this.desciptionMsg =
            "ของรางวัลจะจัดส่งทางไปรษณีย์ กรุณาตรวจสอบ/กรอกข้อมูลให้ครบถ้วน";
          this.btnMsg = "ถัดไป";
          this.$refs.modalRedeemAddress.show(true);
        }
      } else this.$refs.ModalSelectBranch.show(true);
    },
    showPointUsedModal() {
      this.$refs.modalRedeemPoint.show(true);
    },
    handleModalPointClick() {
      this.$refs.ModalSelectBranch.confirmRedeem();
    },
    async refreshPage() {
      await this.$store.dispatch("refreshUserProfile");
      this.$router.replace("/reward");
    },
    success(value) {
      this.msg = value;
      this.$refs.modalAlert.show(true);
    },
    error(value) {
      this.msg = value;
      this.$refs.modalError.show(true);
    },
    async handleAddressFill() {
      await this.$store.dispatch("refreshUserProfile");
      if (this.addressFlag) {
        this.$router.push("/reward");
      } else {
        this.$router.push(
          `/editaddress/${this.id}?type=${this.$route.query.type}&o_id=0&qty=${this.redeemQty}`
        );
      }
    },
    showLoading() {
      this.$refs.modalLoading.show();
    },
    hideLoading() {
      this.$refs.modalLoading.hide();
    },
    redeemSuccess() {
      this.hideModalClose = true;
      this.desciptionMsg =
        "ขอบคุณสำหรับการแลกของรางวัล กรุณารอรับสินค้าตามที่อยู่ที่ได้ลงทะเบียนในระบบ";
      this.btnMsg = "กลับหน้าหลัก";
      this.titleMsg = "แลกของรางวัลเสร็จสิ้น";
      this.$refs.modalRedeemAddress.show(true);
    },
    redeemError(data) {
      this.msg = data.detail;
      this.$refs.modalError.show(true);
    },
    addQty() {
      if (this.redeemQty >= this.detail.total_redeemable) return;
      this.redeemQty += 1;
    },
    onBranchChange(val) {
      const point = this.branch.find(el => el.id == val);

      this.branchPoint = point ? point.point : 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.status-reward {
  color: lightgreen;
  font-weight: 600;
  font-size: var(--text-md);
  text-align: right;
}
.status-false {
  color: red;
  font-weight: 600;
  font-size: var(--text-md);
  text-align: right;
}

.line-clamp-2 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.receive-point {
  color: #fff;
  text-align: right;
  font-size: var(--text-sm);
}

::v-deep a {
  color: gray;
}
::v-deep li.nav-item {
  width: 50%;
  text-align: center;
}
.footer-bottom {
  position: relative;
}
.btn-redeem {
  position: fixed;
  width: 90%;
  bottom: 10px;
  left: 20px;
  right: 10px;
  border: none;
  background-color: #000;
  color: #fff;
  padding: 10px 0;
}

.btn-gray {
  background: #000;
  border-color: transparent;
  color: #fff;
}

.sub-description {
  padding: 10px;
  border-bottom: 1px solid gray;
  font-weight: 600;
}
::v-deep p img {
  width: 100% !important;
  height: auto !important;
}
::v-deep img {
  width: 100% !important;
  height: auto !important;
}
::v-deep table {
  width: 100% !important;
  height: auto !important;
}
::v-deep tr {
  height: auto !important;
}
::v-deep td {
  height: auto !important;
}
.qty {
  width: 30px;
  text-align: center;
}
.point-icon-color {
  color: #fff;
  // padding-left: 4px;
  width: 18px;
  padding: 0px 2px;
}
</style>
