<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <main v-else>
      <RewardDetailPanelNewLayout
        v-if="$store.state.theme.line_layout == 'Compact'"
        :detail="privilegeDetail"
        :branch="branchList"
        :addressFlag="addressFlag"
        :id="parseInt(id)"
        :totalPoint="totalPoint"
        :addressList="addressList"
        :buttonName="buttonName"
      />
      <RewardDetailPanel
        v-else
        :detail="privilegeDetail"
        :branch="branchList"
        :addressFlag="addressFlag"
        :id="parseInt(id)"
        :totalPoint="totalPoint"
        :addressList="addressList"
        :buttonName="buttonName"
      />
    </main>
  </div>
</template>

<script>
import OtherLoading from "@/components/other/OtherLoading";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import RewardDetailPanel from "@/components/reward/detail/RewardDetailPanel";
import RewardDetailPanelNewLayout from "@/components/reward/detail/RewardDetailPanelNewLayout";
export default {
  components: {
    Breadcrumb,
    RewardDetailPanel,
    RewardDetailPanelNewLayout,
    OtherLoading
  },
  metaInfo() {
    return {
      title: this.$route.query.title
    };
  },
  data() {
    return {
      breadcrumbList: [
        {
          text: "รายละเอียดของรางวัล",
          to: "/reward"
        }
      ],
      privilegeDetail: {},
      id: this.$route.params.rewardId,
      isLoading: true,
      branchList: [],
      addressFlag: false,
      totalPoint: 0,
      addressList: {},
      buttonName: ""
    };
  },
  async created() {
    await this.getRedeemInfo();
    if (this.$cookies.get("hourglass_token")) {
      await this.getBranch();
      await this.getPointHistoryLog();
    }
  },
  methods: {
    async getRedeemInfo() {
      let type = this.$route.query.type;
      let o_id = this.$route.query.o_id;
      this.$axios
        .get(
          `${process.env.VUE_APP_API}/api/v1/redeem/info/${this.id}/${type}/${o_id}`
        )
        .then(
          async data => {
            this.isLoading = false;
            if (data.result == 1) {
              this.privilegeDetail = data.detail.redeem_item;
              this.buttonName = data.detail.button_name;
              if (data.detail.redeem_item.pick_up_type_id === 2) {
                await this.checkAddress();
              }
            }
          },
          error => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
              console.log(error);
            }
          }
        );
    },
    async checkAddress() {
      await this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/User/VerifyAddress`)
        .then(async data => {
          if (data.result === 1) {
            // this.addressFlag = data.detail.has_address;
            this.addressList = {
              first_name_th: data.detail.first_name,
              last_name_th: data.detail.last_name,
              telephone: data.detail.telephone,
              home_address: data.detail.home_address,
              district: data.detail.district,
              province: data.detail.province,
              subdistrict: data.detail.subdistrict,
              zip_code: data.detail.zip_code
            };
          }
        });
    },
    async getBranch() {
      this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/Redeem/get_branch_list`)
        .then(
          async data => {
            this.isLoading = false;
            if (data.result == 1) {
              this.branchList = data.detail;
            }
          },
          error => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
              console.log(error);
            }
          }
        );
    },
    async getPointHistoryLog() {
      this.$axios
        .post(`${process.env.VUE_APP_API}/api/v1/user/PointLogByBranch`, {
          BranchId: 0
        })
        .then(data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.totalPoint = data.detail.last_point;
          }
        });
      // var object = await this.$store.dispatch("getUserApi");
      // console.log(object);
    }
  }
};
</script>

<style lang="scss" scoped></style>
